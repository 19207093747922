<template>
  <div class="wrap">
    <DetailPage
      ref="detailPage"
      v-model="options"
      @handleEvent="handleEvent"
    ></DetailPage>
    <!-- 弹窗 -->
    <!-- <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" /> -->
  </div>
</template>

<script>
import DetailPage from "@/components/billDetailPage"; //表格页面组件
// import Dialog from "@/components/dialog"; //公共弹窗
import { mixin } from "@/components/billDetailPage/mixin.js";
import {
  saveCostModify,
  updateCostModify,
  detailCostModify,
  updateStatusCostModify,
  getGoods,
} from "@/api/store/bill/costPriceAdjust.js";
import { fcount } from "@/utils";
export default {
  name: "CostPriceAdjustDetail",
  mixins: [mixin],
  components: {
    DetailPage,
    // Dialog,
  },
  data() {
    return {
      requests: {
        add: saveCostModify,
        update: updateCostModify,
        updateStatus: updateStatusCostModify,
        detail: detailCostModify,
      },
      options: {
        type: "CostPriceAdjustDetail",
        quitPageName: "CostPriceAdjust",
        loading: false,
        check: [], // 选中数据
        rowKey: "billId",
        labelWidth: 90,
        list: [{}],
        disabled: false,
        listName: "details",
        showProp: "goodsNo",
        billType: 130109,
        billNo: "",
        billDate: "",
        billName: "",
        storeId: "",
        handBillNo: "",
        billRemark: "",
        tableIndex: 0,
        tableKey: "goodsId",
        //基本信息
        formItemOpt: [
          {
            label: "单据编号",
            type: "input",
            model: "billNo",
            disabled: true,
            originalDisabled: true,
            rules: [
              {
                required: true,
                message: "请输入单据编号",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            label: "单据日期",
            type: "date",
            model: "billDate",
            rules: [
              {
                required: true,
                message: "请输入单据日期",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            label: "手工单据号",
            type: "input",
            model: "handBillNo",
            labelWidth: "90px",
            maxlength: 40,
          },
          this.$select({
            key: "listStore",
            option: {
              model: "storeId",
              rules: [
                {
                  required: true,
                  message: "请选择调整仓库",
                  trigger: ["blur", "change"],
                },
              ],
              option: {
                disabled: () => {
                  return this.options.list.some((item) => item.goodsId);
                },
              },
            },
          }),
          {
            label: "单据备注",
            type: "textarea",
            filter: "billRemark",
            model: "billRemark",
            option: {
              maxlength: "80",
              placeholder: "单据备注(1-80字符之间)",
            },
          },
        ],
        //按钮
        buttons: [
          {
            click: "save",
            label: "保存",
            type: "primary",
          },
          {
            click: "save-add",
            label: "保存并新增",
            type: "primary",
          },
          {
            click: "audit",
            label: "审核",
            type: "",
          },
          {
            click: "termination",
            label: "终止",
            alertText: "确认要终止该单据吗？",
          },
          {
            click: "quit",
            label: "退出",
          },
        ],
        //表格
        columns: [
          {
            prop: "goodsNo",
            label: "商品编码",
            minWidth: 140,
            align: "center",
            type: "remoteSelect",
            disabled: false,
            option: this.$select({
              key: "listGoods",
              option: {
                option: {
                  remote: getGoods,
                  remoteBody: () => {
                    return { storeId: this.options.storeId || "" };
                  },
                  change: (val, obj, columnIndex) => {
                    if (!this.options.storeId) {
                      this.$message.warning("请选择仓库");
                      this.options.list[obj.index] = {};
                      return;
                    }
                    this.$set(this.options.list, obj.index, {
                      ...obj,
                      finalQty: obj?.stockInfo[0]?.finalQty,
                      finalPrice: obj?.stockInfo[0]?.costPrice,
                      finalMoney: obj?.stockInfo[0]?.finalMoney,
                    });
                    const xTable = this.$refs.detailPage.$refs.vxeTable.$refs.xTable;
                    xTable.setEditRow(xTable.getData()[obj.index]);
                    xTable.setCurrentRow(xTable.getData()[obj.index]);
                  },
                  tableChange: (propValue, row) => {
                    this.handleEvent("getSelectData", row);
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择商品",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: this.$dialog({
                          key: "goods",
                          option: {
                            treeApi: undefined,
                            queryParams: () => {
                              return { storeId: this.options.storeId || "" };
                            },
                            table: {
                              ...this.$dialog({ key: "goods" }).table,
                              list: getGoods,
                              pagination: {
                                ...this.$dialog({ key: "goods" }).table.pagination,
                                goodsTypes: undefined,
                              },
                            },
                          },
                        }),
                      },
                    },
                  ],
                },
              },
            }).option,
            fixed: "left",
            rules: true,
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 150,
            align: "center",
            fixed: "left",
          },
          {
            prop: "barcode",
            label: "条码",
            minWidth: 120,
            align: "center",
            fixed: "left",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
            fixed: "left",
          },
          {
            prop: "unitName",
            label: "基本单位",
            minWidth: 120,
            align: "center",
            fixed: "left",
          },
          {
            label: "调整前",
            minWidth: 120,
            align: "center",
            children: [
              {
                prop: "finalQty",
                label: "结存数量",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "finalPrice",
                label: "结存单价",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "finalMoney",
                label: "结存金额",
                minWidth: 120,
                align: "center",
              },
            ],
          },
          {
            prop: "modifyValue",
            label: "调整成本",
            minWidth: 120,
            align: "center",
            type: "input",
            input: "changeModifyValue",
            disabled: false,
            rules: true,
          },
          {
            label: "调整后",
            minWidth: 120,
            align: "center",
            children: [
              {
                prop: "planFinalPrice",
                label: "结存单价",
                minWidth: 120,
                align: "center",
                type: "input",
                input: "changePlanFinalPrice",
                disabled: false,
                rules: true,
              },
              {
                prop: "planFinalMoney",
                label: "结存金额(元)",
                minWidth: 120,
                align: "center",
              },
            ],
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 200,
            align: "center",
            type: "input",
            input: "sumBalance",
            notVerifyNum: true,
            disabled: false,
          },
        ],
        //校验
        rules: {
          goodsNo: [{ required: true, message: "请选择商品" }],
          modifyValue: [{ required: true, message: "请输入调整成本" }],
          planFinalPrice: [{ required: true, message: "请输入结存金额" }],
        },
        summary: ["finalQty", "finalMoney", "planFinalMoney"],
      },
    };
  },
  async created() {
    this.init();
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "changeModifyValue": // 成本调整-调整成本
          this.$set(
            this.options.list[row.index],
            "modifyValue",
            this.$syInput(2, this.options.list[row.index].modifyValue)
          );
          this.$set(
            this.options.list[row.index],
            "planFinalMoney",
            fcount([row.finalMoney, this.options.list[row.index].modifyValue], "-")
          );
          this.$set(
            this.options.list[row.index],
            "planFinalPrice",
            this.$syInput(
              2,
              fcount([this.options.list[row.index].planFinalMoney, row.finalQty], "/")
            )
          );
          break;
        case "changePlanFinalPrice":
          this.$set(
            this.options.list[row.index],
            "planFinalPrice",
            this.$syInput(2, this.options.list[row.index].planFinalPrice)
          );
          this.$set(
            this.options.list[row.index],
            "planFinalMoney",
            fcount([this.options.list[row.index].planFinalPrice, row.finalQty], "*")
          );
          this.$set(
            this.options.list[row.index],
            "modifyValue",
            this.$syInput(
              2,
              fcount([row.finalMoney, this.options.list[row.index].planFinalMoney], "-")
            )
          );
          break;
        case "getSelectData":
          if (!row || !row.check || row.check.length <= 0) return;
          const { check } = row;
          this.options.columns[0].option.showItem = check;
          let tableIndex = this.options.tableIndex;
          this.options.tableIndex = undefined;
          this.options.list.splice(
            tableIndex,
            1,
            ...check.map((item) => ({
              ...item,
              finalQty: item?.stockInfo[0]?.finalQty,
              finalPrice: item?.stockInfo[0]?.costPrice,
              finalMoney: item?.stockInfo[0]?.finalMoney,
            }))
          );
          //去重
          break;
        case "tableIndex":
          this.options.tableIndex = row;
          break;
        case "save":
        case "save-add":
        case "audit":
        case "termination":
          try {
            await this.handleForm(type, row);
          } catch (err) {}
          break;
        case "quit":
          this.quit();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
