var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("TablePage", {
        ref: "tablePage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("c-dialog", {
        attrs: {
          title: "商品到期日期明细",
          percentageWidth: "60%",
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingTable,
                        expression: "loadingTable",
                      },
                    ],
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      border: "",
                      height: "500px",
                      "row-class-name": _vm.tableRowClassName,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        type: "index",
                        width: "80",
                        label: "序号",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "billNo",
                        label: "单据编号",
                        "min-width": "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "billDate",
                        label: "单据日期",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "produceDate",
                        label: "生产日期",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "dueDate",
                        label: "到期日期",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "finalQty",
                        label: "当前库存",
                        "min-width": "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "inQty",
                        label: "入库数量",
                        "min-width": "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "qualityDealTypeName",
                        label: "处理状态",
                        "min-width": "80",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "auditTime",
                        label: "记账时间",
                        "min-width": "155",
                      },
                    }),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    totalCom: _vm.total,
                    isSelectAll: false,
                    leftTotalCom: false,
                  },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.dialogFormVisible = false
                      },
                    },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }