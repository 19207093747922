<template>
  <!-- 仓库调出单明细 -->
  <div class="check-detail">
    <StoreDetail
      :billType="billType"
      :uuid="uuid"
      :shopType="shopType"
      ref="storeDetail"
      :tableMiddleSpecialCount="1"
    >
      <!-- 插槽 -->
      <template #selectOneArea="scoped">
        <!-- <div class="searchItem">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              v-model="scoped.form.billDate"
              type="date"
              placeholder="选择日期"
              :disabled="scoped.disabled"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div> -->
        <div class="searchItem">
          <el-form-item label="调出仓库" prop="outStoreId">
            <!-- 仓库自定义下拉框 -->
            <SelectFrame
              v-show="shopType == 1"
              ref="selectOutStore"
              v-model="scoped.form.outStoreName"
              :tableData="scoped.storeData"
              :disabled="scoped.disabled"
              @getPitchId="scoped.getDiyselectId($event, 'outStoreId', 'form')"
              @clickAdd="handleStore('add', 'outStoreId')"
              @click="handleStore('update', 'outStoreId')"
              :normalizer="scoped.normalizerStore"
            ></SelectFrame>
            <SelectRemote
              v-show="shopType == 2"
              v-model="scoped.form.outStoreId"
              :option="
                $select({
                  key: 'listStore',
                  option: {
                    option: {
                      disabled: scoped.disabled,
                    },
                  },
                }).option
              "
              @selectChange="scoped.getDiyselectId($event, 'outStoreId', 'form')"
            />
          </el-form-item>
        </div>
        <div class="searchItem">
          <el-form-item label="调入仓库" prop="inStoreId">
            <!-- 仓库自定义下拉框 -->
            <SelectFrame
              v-show="shopType == 1"
              ref="selectInStore"
              v-model="scoped.form.inStoreName"
              :tableData="scoped.storeData"
              @getPitchId="scoped.getDiyselectId($event, 'inStoreId', 'form')"
              @clickAdd="handleStore('add', 'inStoreId')"
              @click="handleStore('update', 'inStoreId')"
              :normalizer="scoped.normalizerStore"
              @selectRowObj="selectChange('', $event)"
              :disabled="scoped.disabled"
            ></SelectFrame>
            <SelectLocal
              v-show="shopType == 2"
              v-model="scoped.form.inStoreId"
              :option="
                $select({
                  key: 'listFranchiseStore',
                  option: {
                    option: {
                      disabled: scoped.disabled,
                    },
                  },
                }).option
              "
              @selectChange="selectChange"
            />
          </el-form-item>
        </div>
      </template>
      <template #tableSpecialFront>
        <el-table-column
          label="库存数"
          align="center"
          min-width="120"
          prop="stockQtyText"
        >
        </el-table-column>
        <el-table-column
          label="订货数量"
          align="center"
          prop="importUnitQty"
          show-overflow-tooltip
          width="120"
        >
        </el-table-column>
      </template>

      <template #tableSpecial>
        <el-table-column label="进价金额" align="center" min-width="150">
          <el-table-column
            label="单位进价(元)"
            align="center"
            width="120"
            prop="unitPurPrice"
          >
          </el-table-column>
          <el-table-column
            label="进价金额(元)"
            align="center"
            width="120"
            prop="unitPurMoney"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="售价金额" align="center" min-width="150">
          <el-table-column
            label="单位售价(元)"
            align="center"
            width="120"
            prop="unitSalePrice"
          >
          </el-table-column>
          <el-table-column
            label="售价金额(元)"
            align="center"
            width="120"
            prop="unitSaleMoney"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="成本金额" align="center" min-width="150">
          <el-table-column
            label="单位成本价"
            align="center"
            prop="unitCostPrice"
            show-overflow-tooltip
            width="120"
          >
            <template v-slot="scope">
              <span>{{ $syInput(2, scope.row.unitCostPrice) }}</span>
            </template>
          </el-table-column>

          <el-table-column
            label="单位成本额"
            align="center"
            prop="unitCostMoney"
            show-overflow-tooltip
            width="120"
          >
            <template v-slot="scope">
              <span>{{ $syInput(3, scope.row.unitCostMoney) }}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </template>
    </StoreDetail>
    <!-- 选择仓库对话框 -->
    <selectStore :OpenStore.sync="OpenStore" @getStore="getStore"></selectStore>
    <!-- 新增仓库对话框 -->
    <storeAdd :OpenStoreAdd.sync="OpenStoreAdd" @getStore="getStore"></storeAdd>
  </div>
</template>

<script>
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import StoreDetail from "@/views/components/bill/storeDetail.vue";
import selectStore from "@/views/components/selectStore"; //选择仓库组件
import storeAdd from "@/views/components/selectStore/storeAdd.vue"; //新增仓库组件
import { number } from "echarts/lib/export";
import { listGoods } from "@/api/goods/goods"; //商品列表
export default {
  name: "storeMoveOutDetail",
  components: { StoreDetail, selectStore, storeAdd, SelectRemote, SelectLocal },
  data() {
    return {
      shopType: 1, //仓库类型
      billType: "",
      //打开选择仓库开关
      OpenStore: false,
      //打开新增仓库开关
      OpenStoreAdd: false,
      //仓库类型
      storeType: undefined,
      uuid: "179ca019-39ca-cca7-9a6f-ff913d480bc5",
      //搜索框ref
      ref: undefined,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // 访问组件实例 vm 中的数据和方法
      vm.shopType = vm.$route.query.shopType ? Number(vm.$route.query.shopType) : 1;
      vm.billType = "130105";
      vm.loading = false;
    });
  },
  methods: {
    // 下拉框
    async selectChange(val, obj = {}) {
      this.$refs.storeDetail.form.priceCaseId = obj.priceCaseId;
      console.log("到这里了嘛源单", this.$refs.storeDetail.form.details);
      const isSource = this.$refs.storeDetail.form.details.some(
        (item) => item.sourceBillId
      );
      if (isSource) return (this.$refs.storeDetail.form.details = [{}]);

      // 刷新调拨价
      if (
        this.$refs.storeDetail.form.details &&
        this.$refs.storeDetail.form.details.some((item) => item.goodsId)
      ) {
        try {
          const { rows } = await listGoods({
            priceCaseId: obj.priceCaseId,
            storeId: this.$refs.storeDetail.form.inStoreId,
            goodsIds: this.$refs.storeDetail.form.details.map((item) => item.goodsId),
            pageNum: 1,
            pageSize: 9999,
            isUseShopTransferPrice: true,
          });
          this.$refs.storeDetail.form.details = this.$refs.storeDetail.form.details.map(
            (item) => {
              const curItem = rows.find((x) => x.goodsId === item.goodsId);
              return {
                ...item,
                units: curItem.units,
              };
            }
          );
          this.$refs.storeDetail.form.details.forEach((item, index) => {
            if (item.goodsId)
              this.$refs.storeDetail.changeUnitPrice(
                item.unitId,
                item.units,
                index,
                false
              );
          });
        } catch (err) {}
      }
    },
    //新增仓库/选择仓库
    handleStore(type, name) {
      if (type === "add") {
        this.OpenStoreAdd = true;
      } else {
        this.OpenStore = true;
      }
      if (name === "outStoreId") {
        this.storeType = name;
        this.ref = "selectOutStore";
      } else {
        this.storeType = name;
        this.ref = "selectInStore";
      }
    },
    //调用storeDetail组件的方法  把选中的仓库/新增仓库传给下拉框
    getStore(event) {
      this.$refs.storeDetail.getStore(event, "form", this.storeType, this.ref, true);
    },
  },
};
</script>
