var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "check-detail" },
    [
      _c("StoreDetail", {
        ref: "storeDetail",
        attrs: {
          billType: _vm.billType,
          uuid: _vm.uuid,
          shopType: _vm.shopType,
          tableMiddleSpecialCount: 1,
        },
        scopedSlots: _vm._u([
          {
            key: "selectOneArea",
            fn: function (scoped) {
              return [
                _c(
                  "div",
                  { staticClass: "searchItem" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "调出仓库", prop: "outStoreId" } },
                      [
                        _c("SelectFrame", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.shopType == 1,
                              expression: "shopType == 1",
                            },
                          ],
                          ref: "selectOutStore",
                          attrs: {
                            tableData: scoped.storeData,
                            disabled: scoped.disabled,
                            normalizer: scoped.normalizerStore,
                          },
                          on: {
                            getPitchId: function ($event) {
                              return scoped.getDiyselectId(
                                $event,
                                "outStoreId",
                                "form"
                              )
                            },
                            clickAdd: function ($event) {
                              return _vm.handleStore("add", "outStoreId")
                            },
                            click: function ($event) {
                              return _vm.handleStore("update", "outStoreId")
                            },
                          },
                          model: {
                            value: scoped.form.outStoreName,
                            callback: function ($$v) {
                              _vm.$set(scoped.form, "outStoreName", $$v)
                            },
                            expression: "scoped.form.outStoreName",
                          },
                        }),
                        _c("SelectRemote", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.shopType == 2,
                              expression: "shopType == 2",
                            },
                          ],
                          attrs: {
                            option: _vm.$select({
                              key: "listStore",
                              option: {
                                option: {
                                  disabled: scoped.disabled,
                                },
                              },
                            }).option,
                          },
                          on: {
                            selectChange: function ($event) {
                              return scoped.getDiyselectId(
                                $event,
                                "outStoreId",
                                "form"
                              )
                            },
                          },
                          model: {
                            value: scoped.form.outStoreId,
                            callback: function ($$v) {
                              _vm.$set(scoped.form, "outStoreId", $$v)
                            },
                            expression: "scoped.form.outStoreId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "searchItem" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "调入仓库", prop: "inStoreId" } },
                      [
                        _c("SelectFrame", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.shopType == 1,
                              expression: "shopType == 1",
                            },
                          ],
                          ref: "selectInStore",
                          attrs: {
                            tableData: scoped.storeData,
                            normalizer: scoped.normalizerStore,
                            disabled: scoped.disabled,
                          },
                          on: {
                            getPitchId: function ($event) {
                              return scoped.getDiyselectId(
                                $event,
                                "inStoreId",
                                "form"
                              )
                            },
                            clickAdd: function ($event) {
                              return _vm.handleStore("add", "inStoreId")
                            },
                            click: function ($event) {
                              return _vm.handleStore("update", "inStoreId")
                            },
                            selectRowObj: function ($event) {
                              return _vm.selectChange("", $event)
                            },
                          },
                          model: {
                            value: scoped.form.inStoreName,
                            callback: function ($$v) {
                              _vm.$set(scoped.form, "inStoreName", $$v)
                            },
                            expression: "scoped.form.inStoreName",
                          },
                        }),
                        _c("SelectLocal", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.shopType == 2,
                              expression: "shopType == 2",
                            },
                          ],
                          attrs: {
                            option: _vm.$select({
                              key: "listFranchiseStore",
                              option: {
                                option: {
                                  disabled: scoped.disabled,
                                },
                              },
                            }).option,
                          },
                          on: { selectChange: _vm.selectChange },
                          model: {
                            value: scoped.form.inStoreId,
                            callback: function ($$v) {
                              _vm.$set(scoped.form, "inStoreId", $$v)
                            },
                            expression: "scoped.form.inStoreId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "tableSpecialFront",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: {
                    label: "库存数",
                    align: "center",
                    "min-width": "120",
                    prop: "stockQtyText",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "订货数量",
                    align: "center",
                    prop: "importUnitQty",
                    "show-overflow-tooltip": "",
                    width: "120",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "tableSpecial",
            fn: function () {
              return [
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      label: "进价金额",
                      align: "center",
                      "min-width": "150",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "单位进价(元)",
                        align: "center",
                        width: "120",
                        prop: "unitPurPrice",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "进价金额(元)",
                        align: "center",
                        width: "120",
                        prop: "unitPurMoney",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      label: "售价金额",
                      align: "center",
                      "min-width": "150",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "单位售价(元)",
                        align: "center",
                        width: "120",
                        prop: "unitSalePrice",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "售价金额(元)",
                        align: "center",
                        width: "120",
                        prop: "unitSaleMoney",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      label: "成本金额",
                      align: "center",
                      "min-width": "150",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "单位成本价",
                        align: "center",
                        prop: "unitCostPrice",
                        "show-overflow-tooltip": "",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$syInput(2, scope.row.unitCostPrice)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "单位成本额",
                        align: "center",
                        prop: "unitCostMoney",
                        "show-overflow-tooltip": "",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$syInput(3, scope.row.unitCostMoney)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("selectStore", {
        attrs: { OpenStore: _vm.OpenStore },
        on: {
          "update:OpenStore": function ($event) {
            _vm.OpenStore = $event
          },
          "update:open-store": function ($event) {
            _vm.OpenStore = $event
          },
          getStore: _vm.getStore,
        },
      }),
      _c("storeAdd", {
        attrs: { OpenStoreAdd: _vm.OpenStoreAdd },
        on: {
          "update:OpenStoreAdd": function ($event) {
            _vm.OpenStoreAdd = $event
          },
          "update:open-store-add": function ($event) {
            _vm.OpenStoreAdd = $event
          },
          getStore: _vm.getStore,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }