<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <c-dialog title="商品到期日期明细" percentageWidth="60%" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-table :data="tableData" tooltip-effect="dark" border v-loading="loadingTable" height="500px"
          :row-class-name="tableRowClassName">
          <el-table-column align="center" type="index" width="80" label="序号" />
          <el-table-column align="center" prop="billNo" label="单据编号" min-width="150" />
          <el-table-column align="center" prop="billDate" label="单据日期" min-width="120" />
          <el-table-column align="center" prop="produceDate" label="生产日期" min-width="120" />
          <el-table-column align="center" prop="dueDate" label="到期日期" min-width="120" />
          <el-table-column align="center" prop="finalQty" label="当前库存" min-width="100" />
          <el-table-column align="center" prop="inQty" label="入库数量" min-width="100" />
          <el-table-column align="center" prop="qualityDealTypeName" label="处理状态" min-width="80" />
          <el-table-column align="center" prop="auditTime" label="记账时间" min-width="155" />
        </el-table>
        <bottomPage v-model="queryParams" :getList="getList" :totalCom="total" :isSelectAll="false"
          :leftTotalCom="false" />
      </template>
      <template #buttonList>
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>
<script>
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import TablePage from '@/components/tablePage';
import { getWarranty, dealDueGoods, warrantyGoodsDueDateDetail } from '@/api/store/analyse/qualityalert';
import bottomPage from "@/views/components/bottomPage";
export default {
  name: 'qualityalert',
  components: { TablePage, CDialog, bottomPage },
  data() {
    return {
      rowBookDetailId: '',
      queryParams: {
        pageNum: 1,
        pageSize: 100,
      },
      loadingTable: false,
      tableData: [],
      dialogFormVisible: false,
      options: {
        mutiSelect: true, // 多选
        listNo: true, // 序号
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 100
        },
        uuid: '0c025178-23bc-1591-f335-e806210e4e6c',
        getListApi: getWarranty,
        buttons: [
          {
            click: "handleGoods",
            label: "处理商品",
            // icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        body: {}, // 请求内容
        check: [], // 选中数据
        rowKey: "bookDetailId",
        labelWidth: "120",
        title: '保质期',
        search: [
          {
            label: '商品编码',
            type: 'input',
            model: '',
            filter: 'goodsNo'
          },
          {
            label: '商品名称',
            type: 'input',
            model: '',
            filter: 'goodsName'
          },
          {
            type: 'input',
            label: '条码',
            model: '',
            filter: 'barcode'
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级搜索',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' },
          this.$select({ key: "listGoods", option: { seniorSearch: true, option: { multiple: true } } }),
          this.$select({ key: "listStore", option: { seniorSearch: true, option: { multiple: true } } }),
          {
            label: '剩余到期天数',
            type: 'number',
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            placeholder: '请输入剩余到期天数',
            filter: 'remainingDueDays'
          },

          {
            type: 'date',
            label: '到期日期',
            seniorSearch: true,
            model: '',
            filter: 'dueDate'
          },
          {
            label: "处理状态",
            seniorSearch: true,
            type: "local",
            model: "1",
            filter: "qualityDealType",
            option: {
              clearable: true,
              data: [
                { label: "待处理", value: "1" },
                { label: "已处理", value: "2" },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
        ],
        columns: [
          {
            prop: 'billNo',
            label: '单据编号',
            type: "link",
            click: "routerLink",
            minWidth: 150
          },
          {
            prop: 'goodsNo',
            label: '商品编码',
            minWidth: 150
          },
          {
            prop: 'goodsName',
            label: '商品名称',
            minWidth: 150
          },
          {
            prop: 'barcode',
            label: '条码',
            minWidth: 120
          },
          {
            prop: 'goodsSpec',
            label: '规格',
            minWidth: 80
          },
          {
            prop: 'unitName',
            label: '基本单位',
            minWidth: 80
          },
          {
            prop: 'storeNo',
            label: '仓库编号',
            minWidth: 100
          },
          {
            prop: 'storeName',
            label: '仓库名称',
            minWidth: 120
          },
          {
            prop: 'finalQty',
            label: '当前库存', // 及时库存
            minWidth: 80
          },
          {
            prop: 'produceDate',
            label: '生产日期',
            minWidth: 100
          },
          {
            prop: 'qualityDays',
            label: '保质期',
            minWidth: 100
          },
          {
            prop: '11231111',
            label: '保质期单位',
            formatter: () => '天',
            minWidth: 90
          },
          {
            prop: 'dueDate',



            label: '到期日期',
            minWidth: 100
          },
          {
            prop: 'inQty',
            label: '预警数量',
            style: () => {
              return {
                fontWeight: 'bold',
                color: 'red'
              }
            },
            minWidth: 120
          },
          {
            prop: 'qualityAlertDays',
            label: '预警天数',
            minWidth: 100
          },
          {
            prop: 'remainingDueDays',
            label: '剩余到期天数',
            minWidth: 100
          },
          {
            prop: 'alertDate',
            label: '预警日期',
            minWidth: 100
          },
          {
            prop: 'qualityDealTypeName',
            label: '处理状态',
            minWidth: 120
          },
          {
            prop: 'qualityDealBy',
            label: '处理人',
            minWidth: 120
          },
          {
            prop: 'qualityDealTime',
            label: '处理时间',
            minWidth: 160
          },
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'handleGoods':
          const selectData = this.options.check.map((i) => i.bookDetailId);
          console.log(selectData, 'selectData');
          const $this = this
          dealDueGoods({ bookDetailIds: selectData }).then((res) => {
            this.$message.success('处理成功')
            //新增
            $this.$refs.tablePage.getList();
          })
          break
        case 'routerLink':
          this.rowBookDetailId = undefined;
          this.tableData = [];
          this.queryParams = {
            bookId: row.bookId,
            pageNo: 1,
            pageSize: 100,
          }
          this.dialogFormVisible = true;
          this.loadingTable = true;
          this.rowBookDetailId = row.bookDetailId;
          await this.getList();
          this.loadingTable = false;
          break;
        default:
          break
      }
    },
    tableRowClassName({ row }) {
      return row.bookDetailId === this.rowBookDetailId ? 'highlight-row' : '';
    },
    async getList() {
      const res = await warrantyGoodsDueDateDetail(this.queryParams);
      this.tableData = res.rows;
      this.total = res.total;
    }
  }
}
</script>
<style lang="scss">
.highlight-row {
  background-color: #e6f7ff !important; /* 高亮行的背景色 */
}
</style>
