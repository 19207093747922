var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "formOptions",
                  staticClass: "left",
                  attrs: {
                    model: _vm.options,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c("TablePage", {
                    key: "tablePage",
                    ref: "tablePage",
                    on: { handleEvent: _vm.handleEvent },
                    model: {
                      value: _vm.options,
                      callback: function ($$v) {
                        _vm.options = $$v
                      },
                      expression: "options",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "formOptionsDetail",
                  staticClass: "right",
                  attrs: {
                    model: _vm.optionsDetail,
                    rules: _vm.rulesDetail,
                    "label-width": "120px",
                  },
                },
                [
                  _c("TablePage", {
                    key: "tablePageDetail",
                    ref: "tablePageDetail",
                    on: { handleEvent: _vm.handleEventDetail },
                    model: {
                      value: _vm.optionsDetail,
                      callback: function ($$v) {
                        _vm.optionsDetail = $$v
                      },
                      expression: "optionsDetail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }